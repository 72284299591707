<template>
  <div>
    <FormTags
      v-if="user.role === 'admin'"
      v-model="businessSearch"
      class="admin-filter"
      :options="businessOptions"
      button-text="Filter by Client"
    />
    <span v-if="$store.state.auth.selectedBusiness && $store.state.auth.user.role === 'admin'">
      <b-btn
        class="delete-chat"
        variant="text"
        @click="deleteChat"
      >
        <i class="ri-delete-bin-6-line" />
      </b-btn>
    </span>
    <!-- <FormTags
      v-model="projectSearch"
      :options="projectOptions"
      button-text="Filter by project"
    /> -->
    <div
      id="talkjs-container"
      style="height:86vh;"
    >
      <i>Loading chat...</i>
    </div>
  </div>
</template>

<script>
import Talk from 'talkjs'
import { mapState } from 'vuex'
import FormTags from '@/components/FormTags'
import axios from '@/helpers/cleanAxios'

export default {
  name: 'MessageBox',
  components: {
    FormTags,
  },
  props: {
    chat: {
      required: false,
      type: Object,
      default: () => {},
    },
  },
  data () {
    return {
      conversation: null,
      chatbox: null,
      businessSearch: [],
      businessOptions: [],
      projectSearch: [],
      projectOptions: [],
      inbox: null,
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  watch: {
    businessSearch (newVal, oldVal) {
      if (newVal.length) {
        window.talkSession.inbox.setFeedFilter({
          custom: {
            businessId: ['oneOf', newVal],
          },
        })
      } else {
        window.talkSession.inbox.setFeedFilter({})
      }
    },
  //   projectSearch (newVal, oldVal) {
  //     if (newVal.length) {
    //       window.talkSession.inbox.setFeedFilter({
    //         custom: {
    //           projectId: ['oneOf', newVal],
  //         },
  //       })
  //     } else {
    //       window.talkSession.inbox.setFeedFilter({})
  //     }
  //   },
  },
  mounted () {
    if (this.user.role === 'admin') {
      axios.get('/admin/v2/businesses')
        .then(({ data }) => {
          this.businessOptions = data.map(business => {
            return {
              value: business.id,
              text: business.name,
            }
          })
        })
      // axios.get('/admin/v2/projects')
      //   .then(({ data }) => {
      //     console.log(data)
      //     this.projectOptions = data.map(project => {
      //       return {
        //         value: project.id,
      //         text: project.title,
      //       }
      //     })
      //   })
    }

    Talk.ready.then(() => {
      // Creating TalkJS session
      const me = new Talk.User(this.user.id)
      window.talkSession = new Talk.Session({
        appId: process.env.VUE_APP_TALKJS_KEY,
        me: me,
        signature: this.user.chatHash,
      })

      this.inbox = window.talkSession.createInbox()
      this.inbox.on('conversationSelected', (conversation) => {
        this.$emit('conversationSelected', conversation)
      })
      window.talkSession.inbox = this.inbox
      this.inbox.mount(document.getElementById('talkjs-container'))
    })

    // Select inbox when "Chat with CSM" button is clicked and chat exists already
    this.$root.$on('chat_highlight', (args) => {
      this.inbox.select(args)
    })
  },
  methods: {
    deleteChat () {
      const confirmed = confirm('Are you sure about to delete the chat?')
      if (confirmed) {
        axios.delete(`/admin/v2/chat/${this.chat.conversation.id}`)
          .then(() => alert('Deleted'))
          .then(() => {
            window.location.reload()
          })
      }
    },
  },
}

</script>

<style lang="scss" scoped>
  .admin-filter {
    border: none;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px !important;
  }
  .delete-chat {
    position: absolute;
    width: 30px;
    height: fit-content;
    padding: 0px;
    top: 3.5%;
    left: 97%;
  }
</style>
