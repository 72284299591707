<template>
  <b-container class="messages-view ml-3 p-0">
    <b-row>
      <b-col class="text-center">
        <b-spinner
          v-if="loading"
          label="Loading..."
        />
      </b-col>
    </b-row>
    <b-card class="messages-tab mt-4 ml-3">
      <b-row>
        <b-col
          class="p-0"
        >
          <MessageBox
            :chat="conversation"
            @conversationSelected="conversationSelected"
          />
        </b-col>
        <b-col
          v-if="((conversation || {}).others || []).length > 1"
          cols="3"
          class="p-0"
        >
          <ChatSidebar
            :chat="conversation"
            @matchUpdated="matchUpdated"
          />
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>

<script>
import ChatSidebar from '@/components/chat/ChatSidebar.vue'
import MessageBox from '@/components/chat/MessageBox.vue'

export default {
  name: 'Messages',
  components: {
    MessageBox,
    ChatSidebar,
  },
  data () {
    return {
      conversation: {},
      loading: false,
    }
  },
  methods: {
    conversationSelected (conversation) {
      this.conversation = conversation
    },
    matchUpdated (data) {
      console.log(data)
    },
  },
}
</script>

<style lang="scss" scoped>
  .messages-view {
    max-width: 74vw;
    .messages-tab {
      box-shadow: 1px 0px 0px rgba(0, 0, 0, 0.0);
      border-radius: 12px 12px 12px 12px;
      border: none !important;
    }
    .card-body {
      padding: 0px 15px 0px 15px;
    }
  }
</style>
