<template>
  <SidebarRecruiter
    v-if="$store.state.auth.selectedBusiness && match && match.recruiter"
    :match="match"
    @matchUpdated="matchUpdated"
  />
  <SidebarAgency
    v-else-if="$store.state.auth.selectedBusiness && match && match.agency"
    :match="match"
    @matchUpdated="matchUpdated"
  />
  <SidebarProject
    v-else-if="!$store.state.auth.selectedBusiness && match"
    :match="match"
  />
</template>

<script>
import axios from '@/helpers/cleanAxios'
import SidebarRecruiter from './SidebarRecruiter.vue'
import SidebarAgency from './SidebarAgency.vue'
import SidebarProject from './SidebarProject.vue'

export default {
  name: 'ChatSidebar',
  components: {
    SidebarRecruiter,
    SidebarAgency,
    SidebarProject,
  },
  props: {
    chat: {
      required: false,
      type: Object,
      default: () => {},
    },
  },
  data () {
    return {
      match: null,
    }
  },
  watch: {
    chat: {
      handler: function (newVal) {
        if (newVal.conversation?.custom?.matchId) {
          this.matchUpdated(newVal.conversation.custom.matchId)
        } else {
          this.match = null
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    matchUpdated (data) {
      axios.get(`/v2/matches/${data}`)
        .then(({ data }) => {
          this.match = data
        })
    },
  },
}
</script>

<style>

</style>
